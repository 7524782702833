import  { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import NavBar from '../Components/LandingPage/NavBar/NavBar';
import Publicidad1 from '../Components/LandingPage/Publicidad/publicidad1';

const ChatComponent = lazy(() => import('../Components/Home/ChatInterface/ChatComponent'));
const Footer = lazy(() => import('../Components/LandingPage/Footer/Footer'));
const MainPaige = lazy(() => import('../Components/LandingPage/Main/MainPage'));
const RoutesApp = lazy(() => import('../Components/Routes/RoutesApp'));
const Publicidad = lazy(() => import('../../src/Components/LandingPage/Publicidad/Publicidad'));


const RouterApp = () => {
    
    return (
        <Router>
            <div>   
                <NavBarWithConditionalRendering />
                    <Suspense fallback={<div style={{marginTop:'50vh', marginBottom:'50vh',display:'flex', justifyContent:'center', alignItems:'center'}}><CircularProgress disableShrink /></div>}>
                        <Routes>
                            <Route path="/" element={<MainPaige />} />
                            <Route path="/main" element={<MainPaige />} />
                            <Route path="/chat" element={<ChatComponent />} />
                            <Route path="/bvpublicity1" element={<Publicidad1 imageIndex={0} />} />
                            <Route path="/bvpublicity2" element={<Publicidad1 imageIndex={1} />} />
                            <Route path="/bvpublicity3" element={<Publicidad1 imageIndex={2} />} />
                            <Route path="/bvpublicity4" element={<Publicidad1 imageIndex={3} />} />
                            <Route path="/bvpublicity5" element={<Publicidad1 imageIndex={4} />} />
                            <Route path="/bvpublicitybanner" element={<Publicidad />} />
                            <Route path="/*" element={<RoutesApp />} />
                        </Routes>
                    </Suspense>
                <FooterWithConditionalRendering />
            </div>
        </Router>
    );
};

const NavBarWithConditionalRendering = () : any => {
    const location = useLocation();
    const isChatRoute = location.pathname === '/chat';
    const isHomeRoute = location.pathname.startsWith('/home');
    const isPublicidadRoute = location.pathname === '/bvpublicitybanner';
    const isPublicida1 = location.pathname === '/bvpublicity1';
    const isPublicidad2 = location.pathname === '/bvpublicity2';
    const isPublicidad3 = location.pathname === '/bvpublicity3';
    const isPublicidad4 = location.pathname === '/bvpublicity4';
    const isPublicidad5 = location.pathname === '/bvpublicity5';

    return !isChatRoute && 
            !isHomeRoute && 
            !isPublicidadRoute && 
            !isPublicida1 &&
            !isPublicidad2 &&
            !isPublicidad3 &&
            !isPublicidad4 &&
            !isPublicidad5 &&
            <NavBar />;
};

const FooterWithConditionalRendering = () : any => {
    const location = useLocation();
    const isChatRoute = location.pathname === '/chat';
    const isHomeRoute = location.pathname.startsWith('/home');
    const isPublicidadRoute = location.pathname === '/bvpublicitybanner';
    const isPublicida1 = location.pathname === '/bvpublicity1';
    const isPublicidad2 = location.pathname === '/bvpublicity2';
    const isPublicidad3 = location.pathname === '/bvpublicity3';
    const isPublicidad4 = location.pathname === '/bvpublicity4';
    const isPublicidad5 = location.pathname === '/bvpublicity5';

    return !isChatRoute && 
            !isHomeRoute && 
            !isPublicidadRoute && 
            !isPublicida1 &&
            !isPublicidad2 &&
            !isPublicidad3 &&
            !isPublicidad4 &&
            !isPublicidad5 &&
    <Footer />;
};

export default RouterApp;
