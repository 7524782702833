import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define el tipo de acción para setSelectedColor
interface SetSelectedColorPayload {
  color: string;
}

// Define el tipo de acción para setPhoneNumber
interface SetPhoneNumberPayload {
  phoneNumber: string;
}

// Define el tipo de acción para login
interface LoginPayload {
  isAuthenticated: any;
  user: any;
  roles: any;
  idRol:  any ;
  token: any;
  phoneNumber: any;
}

// Estado inicial
const initialState = {
  isAuthenticated: sessionStorage.getItem('isAuthenticated') === 'true',
  user: sessionStorage.getItem('user') || null,
  roles: sessionStorage.getItem('roles') ? JSON.parse(sessionStorage.getItem('roles')!) : [],
  idRol: sessionStorage.getItem('idRol') || null,
  token: sessionStorage.getItem('token') || null,
  selectedColor: '#011730',
  phoneNumber: sessionStorage.getItem('phoneNumber') || '',
};

// Slice de Redux
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<any>) {
      const { isAuthenticated, user, roles, idRol, token, phoneNumber } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.roles = roles;
      state.idRol = idRol;
      state.token = token;
      state.phoneNumber = phoneNumber;

      sessionStorage.setItem('isAuthenticated', String(isAuthenticated));
      sessionStorage.setItem('user', user);
      sessionStorage.setItem('roles', JSON.stringify(roles));
      sessionStorage.setItem('idRol', String(idRol));
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('phoneNumber', phoneNumber);
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.roles = [];
      state.idRol = null;
      state.token = null;
      state.phoneNumber = '';

      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('roles');
      sessionStorage.removeItem('idRol');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('phoneNumber');
    },
    setSelectedColor(state, action: PayloadAction<SetSelectedColorPayload>) {
      state.selectedColor = action.payload.color;
    },
    setPhoneNumber(state, action: PayloadAction<SetPhoneNumberPayload>) {
      state.phoneNumber = action.payload.phoneNumber;
      sessionStorage.setItem('phoneNumber', action.payload.phoneNumber);
    },
  },
});

// Exporta acciones y reducer
export const { login, logout, setSelectedColor, setPhoneNumber } = authSlice.actions;
export default authSlice.reducer;
