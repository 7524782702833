import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem, MenuList, Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
const logo = require('./Images/logo2.png');

const NavBar = () => {
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const isMainRoute : boolean = location.pathname === '/main' || location.pathname === '/';


  const toggleDrawer = (open: boolean) => () : void => {
    setOpenDrawer(open);
  };
  



  const scrollToSectionBeneficios = () : void => {
    const section = document.getElementById('Beneficios-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToSectionPlanes = () : void => {
    const section = document.getElementById('Planes-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToSectionFuncion = () : void=> {
    const section = document.getElementById('Planes-funcion');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const DrawerContent = (
    <Box
      sx={{
        width: 250,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <MenuList>
         <MenuItem component={Link} to="/main">
            <ListItemIcon>
                <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>inicio</ListItemText>
          </MenuItem>  
          <MenuItem component={Link} to="/contactanos">
            <ListItemIcon>
                <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Contáctanos</ListItemText>
          </MenuItem>   
          <MenuItem component={Link} to="/nosotros">
            <ListItemIcon>
                <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Nosotros</ListItemText>
          </MenuItem>       
          {isMainRoute && 
                    <><MenuItem onClick={scrollToSectionBeneficios}>
            <ListItemIcon>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Beneficios</ListItemText>
          </MenuItem><MenuItem onClick={scrollToSectionPlanes}>
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Planes</ListItemText>
            </MenuItem><MenuItem onClick={scrollToSectionFuncion}>
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>¿Cómo funciona?</ListItemText>
            </MenuItem></>
          
          }
  
      </MenuList>
    </Box>
  );

  return (
    <>
      <AppBar sx={{ backgroundColor: '#12325A', boxShadow:'none', margin:'0'}} position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            
            <Link to="/main">
              <img style={{ width: '6vh', cursor: 'pointer' }} src={logo} alt="logo" className='logo' />
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
                <Link to="/main" style={{ textDecoration: 'none' }}>
                  <Button sx={{ my: 2, fontWeight: '700', color: '#fff', display: 'block' }}>
                    Inicio
                  </Button>
                </Link>
                <Link to="/nosotros" style={{ textDecoration: 'none' }}>
                  <Button sx={{ my: 2, fontWeight: '700', color: '#fff', display: 'block' }}>
                    Nosotros
                  </Button>
                </Link> 
                {isMainRoute && 
                <>
                <Button onClick={scrollToSectionBeneficios} sx={{ my: 2, fontWeight: '700', color: '#fff', display: 'block' }}>
                    Beneficios
                  </Button><Button onClick={scrollToSectionPlanes} sx={{ my: 2, fontWeight: '700', color: '#fff', display: 'block' }}>
                    Planes
                  </Button><Button onClick={scrollToSectionFuncion} sx={{ my: 2, fontWeight: '700', color: '#fff', display: 'block' }}>
                    ¿Como funciona?
                  </Button></>
                }
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'center' }}>
              <Link to="/contactanos" style={{ textDecoration: 'none' }}>
                  <Tooltip title="Iniciar sesión">
                      <IconButton sx={{ p: 0 }} aria-label="Iniciar sesión">
                          <Typography color='#fff' marginRight='.5rem'>Ayuda</Typography>
                      </IconButton>
                  </Tooltip>
              </Link>
            </Box>
            {/* <Box sx={{ flexGrow: 0 }}>
              <Link to="/login" style={{ textDecoration: 'none' }}>
                  <Tooltip title="Iniciar sesión">
                      <IconButton sx={{ p: 0 }} aria-label="Iniciar sesión">
                          <Typography color='#fff' marginRight='.5rem'>Iniciar sesión</Typography>
                          <LoginIcon style={{ color: '#fff' }} />
                      </IconButton>
                  </Tooltip>
              </Link>
            </Box> */}

          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        sx={{ display: { xs: 'block', sm: 'none' } }}
      >
        {DrawerContent}
      </Drawer>
    </>
  );
};

export default NavBar;  
